import React, { useEffect, useContext } from "react";
import { SkeletonPage, Layout, Spinner } from "@shopify/polaris";

import { useNavigate, useSearchParams } from "react-router-dom";

import { AuthenticationContext } from "../context/AutheticationContext";

import useLocalStorage from "../hooks/useLocalStorage";

export default function Login() {
  const { token, handleLogin } = useContext(AuthenticationContext);
  const [urlSearchParams] = useSearchParams();
  const [currentLocation] = useLocalStorage(
    "current_location",
    urlSearchParams.get("callback") || "/installations"
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (token && currentLocation) {
      return navigate(currentLocation);
    }

    const paramsToken = urlSearchParams.get("token");

    if (paramsToken) {
      handleLogin(paramsToken);
    } else {
      window.location = process.env.REACT_APP_AUTH_URI;
    }
  }, [token, currentLocation]);

  // TODO: voltar aqui quando o layout estiver pronto
  return (
    <SkeletonPage>
      <Layout>
        <Spinner />
      </Layout>
    </SkeletonPage>
  );
}
