import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";

const PrivateRoutes = () => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.token) {
    return <Outlet />;
  } else {
    return <Navigate to={`/login?callback=${location.pathname}`} replace />;
  }
};

export default PrivateRoutes;
