import React from "react";
import useAuth from "../hooks/useAuth";
import {
  ApolloClient,
  createHttpLink,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API,
});

function APIProvider({ children }) {
  const auth = useAuth();
  const links = ApolloLink.from([
    setContext((_, { headers }) => {
      const token = auth.token;

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(
          ({ message, locations, path, extensions: { code } }) => {
            if (code === "UNAUTHENTICATED") {
              auth.handleLogout();
            }

            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
        );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    httpLink,
  ]);

  const client = new ApolloClient({
    link: links,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export { APIProvider };
