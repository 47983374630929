import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const AuthenticationContext = React.createContext({
  token: null,
  handleLogin: (data) => {},
  handleLogout: () => {},
});

function AuthenticationProvider({ children }) {
  const [token, setToken] = useLocalStorage("@token", null);

  const handleLogin = (newToken) => {
    setToken(newToken);
  };

  const handleLogout = () => {
    setToken(null);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationContext, AuthenticationProvider };
